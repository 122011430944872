body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  color: darkred;
  text-align: center;
  padding: 2em;
}

button {
  border: 1px solid darkred;
  padding: 1em 2em;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 1em;
  text-transform: uppercase;
  font-weight: 700;
  color: darkred;
}

button:hover {
  background-color: rgba(220, 20, 60, 0.21);
}

input, textarea {
  padding: 0.5em 1em;
  outline: none;
  font-size: 1.125em;
  border: 1px solid darkred;
  border-radius: 0.5em;
}

textarea {
  width: 100%;
  max-width: 70vw;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
